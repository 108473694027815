import {
  Box,
  Container,
  Grid,
  Stack,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  footerBg,
  roadmapBg,
  stakingHeroBg,
  stakingPuppie,
} from "../../components/SmallComponents/Images";
import {
  StyledButton,
  StyledTab,
  StyledTitle,
} from "../../components/SmallComponents/AppComponents";
import Header from "../../components/Header";
import StakeBox from "./StakeBox";
import ClaimBox from "./ClaimBox";
import UnstakedBox from "./UnstakedBox";
import { preSaleReadFunction } from "../../ConnectivityAssets/hooks";
import { AppContext } from "../../utils";
import { formatUnits } from "viem";
import StakingTable from "./StakingTable";

function Staking() {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  const { account } = useContext(AppContext);
  const [myStakedTokens, setmyStakedTokens] = useState(0);
  const [myClaimedReward, setmyClaimedReward] = useState(0);
  const [myUnclaimedReward, setmyUnclaimedReward] = useState(0);
  const [lastClaimTime, setlastClaimTime] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [callFunction, setCallFunction] = useState(true);
  const [userData, setUserData] = useState({
    amount: 0,
    rewardClaimed: 0,
    pendingReward: 0,
    lastClaimTime: 0,
  });

  const initVoidSigner = async () => {
    try {
      const userData = await preSaleReadFunction("userStakes");
      setmyStakedTokens(+formatUnits(userData[0]?.toString(), 18));
      setmyClaimedReward(+formatUnits(userData[1]?.toString(), 18));
      setmyUnclaimedReward(+formatUnits(userData[2]?.toString(), 18));
      setlastClaimTime(+formatUnits(userData[3]?.toString(), 18));
      setCallFunction(false);
    } catch (error) {
      setCallFunction(false);
      console.log(error, "ERROR VoidSigner Data");
    }
  };
  useEffect(() => {
    if (account) {
      initVoidSigner();
    }
  }, [callFunction, account]);
  const handleTabChange = (e, newVal) => {
    setTabValue(newVal);
  };
  useEffect(() => {
    if (account) {
      (async () => {
        try {
          const userData = await preSaleReadFunction("userStakes", [account]);
          console.log(userData);
          const obj = {
            amount: parseFloat(
              formatUnits(userData[0]?.toString(), 18)
            )?.toFixed(2),
            rewardClaimed: parseFloat(
              formatUnits(userData[1]?.toString(), 18)
            )?.toFixed(2),
            pendingReward: parseFloat(
              formatUnits(userData[2]?.toString(), 18)
            )?.toFixed(2),
            lastClaimTime: +userData[3]?.toString(),
          };
          setUserData(obj);
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [account]);
  return (
    <>
      <Box
        sx={{
          background: mobileMatches
            ? `linear-gradient(0deg, #00000071 0%, #00000071 100%),url(${stakingHeroBg}) no-repeat center`
            : `url(${stakingHeroBg}) no-repeat center`,
          backgroundSize: { xs: "200% 100%", sm: "100% 100%" },
          // pt: 4,
          pb: 4,
          mb: 2,
          position: "relative",
          zIndex: "0",
        }}
      >
        <Header />
        <Container maxWidth="xl">
          <Grid
            container
            spacing={{ xs: 1, sm: 4 }}
            justifyContent={"center"}
            flexDirection={{ xs: "column-reverse", sm: "row" }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    color: "#75430D",
                    fontSize: { xs: "26px", sm: "36px" },
                    lineHeight: "46px",
                    fontFamily: "Wagoon Bold",
                    textAlign: { xs: "center", sm: "left" },
                    fontWeight: "400",
                    mt: { xs: 2, md: 6 },
                  }}
                >
                  EARN APY <br /> WHILE STAKED
                </Typography>
                <Typography
                  variant="h1"
                  sx={{
                    color: "#75430D",
                    fontSize: { xs: "12px", sm: "14px" },
                    fontFamily: "Montserrat",
                    lineHeight: "16px",
                    fontWeight: "700",
                    textAlign: { xs: "center", sm: "left" },
                    mt: 3,

                    textTransform: "capitalize",
                  }}
                >
                  When you stake your $TOTE tokens you{" "}
                  {!mobileMatches && <br />}
                  can earn a generous APY just for holding.
                </Typography>
                <Box
                  mt={{ xs: 7, md: 4 }}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", sm: "start" },
                  }}
                >
                  <StyledButton width="180px">How to buy</StyledButton>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Box
                  component={"img"}
                  alt=""
                  src={stakingPuppie}
                  width="100%"
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: "#8e6b24",
          mt: 4,
          py: 4,
        }}
      >
        <Container maxWidth="xl">
          <StyledTitle textAlign="center">
            {" "}
            ALL YOUR STAKING NEEDS IN ONE PLACE
          </StyledTitle>
          <Grid container spacing={4} justifyContent={"center"} my={4}>
            <Grid item xs={12} sm={6} md={4}>
              <Box height={{ xs: "inherit", md: "100%" }}>
                <StakeBox
                  currentStaked={myStakedTokens}
                  claimedReward={myClaimedReward}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box height={{ xs: "inherit", md: "100%" }}>
                <ClaimBox pendingReward={myUnclaimedReward} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box height={{ xs: "inherit", md: "100%" }}>
                <UnstakedBox />
              </Box>
            </Grid>
            <Grid item xs={12} md={9} mt={{ xs: 0, md: 6 }}>
              <Box
                height={{ xs: "inherit", md: "100%" }}
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "20px",
                  py: 3,
                  height: "fit-content",
                  px: 2,
                }}
              >
                <Stack height={"100%"} gap={{ xs: 2, md: 0 }}>
                  {/* <Tabs
                    variant="scrollable"
                    scrollButtons={false}
                    value={tabValue}
                    onChange={handleTabChange}
                    sx={{
                      width: "fit-content",
                      px: { xs: 0, sm: 1 },
                      mt: { xs: 0, md: 2 },
                    }}
                    TabIndicatorProps={{
                      style: { backgroundColor: "inherit" },
                    }}
                  >
                    {["DEPOSITS", "CLAIMS", "WITHDRAWALS"].map((info, i) => (
                      <StyledTab key={info}>{info}</StyledTab>
                    ))}
                  </Tabs> */}
                  <Stack>
                    <StakingTable data={userData} />
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Staking;
