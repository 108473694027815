import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  bitCoinIcon,
  presaleBg,
  usdtIcon,
  bnb,
} from "./SmallComponents/Images";
import {
  StyledButtonTwo,
  StyledInput,
  ToastNotify,
} from "./SmallComponents/AppComponents";
import { AppContext } from "../utils";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import {
  preSaleReadFunction,
  preSaleWriteFunction,
  usdtWriteFunction,
} from "../ConnectivityAssets/hooks";
import { formatUnits, parseUnits } from "viem";
import {
  defaultAddress,
  preSaleAddress,
} from "../ConnectivityAssets/environment";
import Loading from "./SmallComponents/loading";
import TimerCountDown from "./SmallComponents/Timer";
import ClaimDilouge from "./SmallComponents/ClaimDilouge";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function PresaleBox() {
  const mobileMatches = useMediaQuery("(max-width:380px)");
  const { open } = useWeb3Modal();
  const { account } = useContext(AppContext);
  const [endTimeContract, setEndTimeContract] = useState(0);
  const [buyWith, setBuyWith] = useState("BNB");
  const [amountToBuy, setamountToBuy] = useState("");
  const [receiveToken, setReceiveToken] = useState("");
  const [raisedAmount, setRaisedAmount] = useState(0);
  const [tokenPerUSDT, settokenPerUSDT] = useState(0);
  const [tokenPerEth, setTokenPerEth] = useState(0);
  const [claimDilouge, setClaimDilouge] = useState(false);
  const [receiveTokenStatus, setReceiveTokenStatus] = useState(false);
  const [callFunction, setCallFunction] = useState(true);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [loading, setloading] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const toLocalFormat = (val) => {
    return val?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  useEffect(() => {
    if (receiveTokenStatus) {
      if (receiveToken && +receiveToken > 0) {
        if (buyWith === "USDT") {
          setamountToBuy(receiveToken * tokenPerUSDT);
        } else {
          setamountToBuy(receiveToken * tokenPerEth);
        }
      } else {
        setamountToBuy("");
      }
    } else {
      if (amountToBuy && +amountToBuy > 0) {
        if (buyWith === "USDT") {
          setReceiveToken(amountToBuy / tokenPerUSDT);
        } else {
          setReceiveToken(amountToBuy / tokenPerEth);
        }
      } else {
        setReceiveToken("");
      }
    }
  }, [amountToBuy, buyWith, receiveToken]);

  const handleInputChange = (event, status) => {
    const input = event.target.value;
    const newValue = input?.replace(/[^0-9.]/g, "");
    if (!status) {
      setReceiveToken("");
      setamountToBuy(newValue);
      setReceiveTokenStatus(false);
    } else {
      setamountToBuy("");
      setReceiveToken(newValue);
      setReceiveTokenStatus(true);
    }
  };
  useEffect(() => {
    (() => {
      if (window.location.href.includes("?ref=")) {
        let getAddress = window.location.href.split("?ref=")[1];
        let final = getAddress.slice(0, 42);
        localStorage.setItem("TOTE", final);
      }
    })();
  }, [callFunction]);

  const initVoidSigner = async () => {
    try {
      let endTimePresaleContract = await preSaleReadFunction("endTime");
      setEndTimeContract(+endTimePresaleContract?.toString());
      let usdtToToken = await preSaleReadFunction("usdtBuyHelper", [1]);
      settokenPerUSDT(+formatUnits(usdtToToken?.toString(), 18));
      let ethToToken = await preSaleReadFunction("ethBuyHelper", [1]);
      setTokenPerEth(+formatUnits(ethToToken?.toString(), 18));
      let usdRaisedContract = await preSaleReadFunction("usdRaised");
      usdRaisedContract =
        +parseFloat(formatUnits(usdRaisedContract?.toString(), 18))?.toFixed(
          0
        ) + 20678;
      setRaisedAmount(usdRaisedContract);
      setProgressBarValue((usdRaisedContract / 150000) * 100);
      setCallFunction(false);
    } catch (error) {
      setCallFunction(false);
      console.log(error, "ERROR VoidSigner Data");
    }
  };
  useEffect(() => {
    initVoidSigner();
  }, [callFunction]);

  const buyHandler = async () => {
    if (account) {
      if (!amountToBuy) {
        setAlertState({
          open: true,
          message: `Error! Please enter an amount`,
          severity: "error",
        });
      } else {
        try {
          setloading(true);
          let refAddress = localStorage.getItem("TOTE");
          let refAdd = refAddress ? refAddress : defaultAddress;
          if (buyWith === "USDT") {
            await usdtWriteFunction("approve", [
              preSaleAddress,
              parseUnits(amountToBuy.toString(), 18).toString(),
            ]);
            await preSaleWriteFunction("buyWithUSDT", [
              parseFloat(receiveToken)?.toFixed(0)?.toString(),
              // parseUnits(amountToBuy.toString(), 18).toString(),
              refAdd,
            ]);
          } else {
            const updatedAmount = +amountToBuy + 0.0001;
            await preSaleWriteFunction(
              "buyWithEth",
              [parseFloat(receiveToken)?.toFixed(0)?.toString(), refAdd],
              parseUnits(updatedAmount.toString(), 18).toString()
            );
          }
          setAlertState({
            open: true,
            message: "Success! Transaction Confirmed",
            severity: "success",
          });
          setamountToBuy("");
          setReceiveToken(0);
          initVoidSigner();
          setCallFunction(false);
          setloading(false);
        } catch (error) {
          setloading(false);
          setAlertState({
            open: true,
            message: error?.shortMessage,
            severity: "error",
          });
        }
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };
  const buyHandlerTwo = async () => {
    if (account) {
      if (!amountToBuy) {
        setAlertState({
          open: true,
          message: `Error! Please enter an amount`,
          severity: "error",
        });
      } else {
        try {
          setloading(true);
          let refAddress = localStorage.getItem("TOTE");
          let refAdd = refAddress ? refAddress : defaultAddress;
          if (buyWith === "USDT") {
            await usdtWriteFunction("approve", [
              preSaleAddress,
              parseUnits(amountToBuy.toString(), 18).toString(),
            ]);
            await preSaleWriteFunction("buyWithUSDTAndStake", [
              parseFloat(receiveToken)?.toFixed(0)?.toString(),
              // parseUnits(amountToBuy.toString(), 18).toString(),
              refAdd,
            ]);
          } else {
            const updatedAmount = +amountToBuy + 0.0001;
            await preSaleWriteFunction(
              "buyWithEthAndStake",
              [parseFloat(receiveToken)?.toFixed(0)?.toString(), refAdd],
              parseUnits(updatedAmount.toString(), 18).toString()
            );
          }
          setAlertState({
            open: true,
            message: "Success! Transaction Confirmed",
            severity: "success",
          });
          setamountToBuy("");
          setReceiveToken(0);
          initVoidSigner();
          setCallFunction(false);
          setloading(false);
        } catch (error) {
          setloading(false);
          setAlertState({
            open: true,
            message: error?.shortMessage,
            severity: "error",
          });
        }
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };
  return (
    <Box
      sx={{
        background: `url(${presaleBg}) no-repeat center`,
        backgroundSize: "100% 100%",
        px: { xs: 3, sm: 6 },
      }}
    >
      <ClaimDilouge open={claimDilouge} setOpen={setClaimDilouge} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <Stack
        sx={{
          py: 5,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#ffffff",
            fontSize: { xs: "20px", sm: "26px" },
            fontFamily: "Wagoon Bold",
            lineHeight: "30px",
            fontWeight: "400",
            textAlign: "center",
          }}
        >
          UNTIL LISTINGS AND LAUNCH {!mobileMatches && <br />}
          OF Tootie
        </Typography>
        <Box
          sx={{
            backgroundColor: "#E5E5E5",
            boxShadow:
              "0px 5.913842678070068px 14.78460693359375px 0px #00000026,0px 1.4046640396118164px 1.75583016872406px 0px #FFFFFF inset",
            borderRadius: "20px",
            py: 1,
            mt: { xs: 2, sm: 3 },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#151515",
              fontSize: { xs: "14px", sm: "18px" },
              fontFamily: "Poppins",
              lineHeight: "30px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {/* LAST CHANCE TO BUY BEFORE LISTING */}
            NEXT PRICE : 0.0050
          </Typography>
        </Box>
        <Box mt={2}>
          <TimerCountDown time={endTimeContract} />
        </Box>
        <Stack mt={2}>
          <Typography
            variant="h3"
            sx={{
              color: "#ffffff",
              fontSize: { xs: "16px", sm: "20px" },
              fontFamily: "Poppins",
              lineHeight: "30px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            RAISED ${toLocalFormat(raisedAmount)} / ${toLocalFormat(150000)}
          </Typography>
          <Stack
            className="borderImgTwo"
            sx={{
              height: "25px",
              backgroundColor: "#D9D9D90D",
              border: "1px solid #000000",
              mt: 0.5,
              borderRadius: "20px",
              overflow: "hidden",
            }}
          >
            <Stack
              height={"100%"}
              alignItems={"start"}
              justifyContent={"center"}
              position={"relative"}
            >
              <Box
                sx={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  bottom: 0,
                  width: `${progressBarValue}%`,
                  borderRadius: "20px",
                  background: "#F3DC09",
                  zIndex: 0,
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Typography
          variant="h3"
          sx={{
            color: "#ffffff",
            fontSize: { xs: "18px", sm: "22px" },
            fontFamily: "Poppins",
            lineHeight: "30px",
            fontWeight: "600",
            textAlign: "center",
            mt: { xs: 2, sm: 3 },
          }}
        >
          CHOOSE YOUR CURRENCY
        </Typography>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            my: 2,
          }}
        >
          {[
            {
              text: "BNB",
              img: bnb,
            },
            {
              text: "USDT",
              img: usdtIcon,
            },
          ].map(({ text, img }) => (
            <Box
              key={text}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 0.5,
                border: "0.56px solid #000000",
                boxShadow:
                  "0px 5.913842678070068px 14.78460693359375px 0px #00000026,0px 1.4046640396118164px 1.75583016872406px 0px #FFFFFF inset",
                borderRadius: "20px",
                py: 1,
                px: 3,
                backgroundColor: text === buyWith ? "#c29631" : "#E5E5E5",
                width: "100px",
                cursor: "pointer",
              }}
              onClick={() => setBuyWith(text)}
            >
              <Box component={"img"} alt="" src={img} width="22px" />
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#151515",
                  fontSize: { xs: "16px", sm: "18px" },
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                {text}
              </Typography>
            </Box>
          ))}
        </Stack>
        <Typography
          variant="subtitle2"
          sx={{
            color: "#FFFFFF",
            fontSize: { xs: "14px", sm: "16px" },
            fontFamily: "Poppins",
            fontWeight: "400",
            textDecoration: "underline",
            textAlign: "center",
            mt: { xs: 0.5, sm: 1 },
          }}
        >
          1 $TOTE = $0.0040
        </Typography>
        <Stack
          sx={{
            flexDirection: "row",
            gap: { xs: 2, sm: 4 },
            mt: { xs: 2, sm: 3 },
          }}
        >
          <Stack gap={0.5}>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#FFFFFF",
                fontSize: { xs: "13px", sm: "14px" },
                fontFamily: "Poppins",
                fontWeight: "400",
                ml: 1,
              }}
            >
              <span
                style={{
                  fontWeight: "600",
                }}
              >
                {buyWith}
              </span>{" "}
              you pay
            </Typography>
            <Box>
              <StyledInput
                type="text"
                placeholder="0"
                value={amountToBuy}
                onChange={(e) => handleInputChange(e, false)}
              />
            </Box>
          </Stack>
          <Stack gap={0.5}>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#FFFFFF",
                fontSize: { xs: "13px", sm: "14px" },
                fontFamily: "Poppins",
                fontWeight: "400",
                ml: 1,
              }}
            >
              <span
                style={{
                  fontWeight: "600",
                }}
              >
                $TOTE
              </span>{" "}
              you receive
            </Typography>
            <Box>
              <StyledInput
                type="text"
                placeholder="0"
                value={receiveToken}
                onChange={(e) => handleInputChange(e, true)}
                // InputProps={{
                //   readOnly: true,
                // }}
              />
            </Box>
          </Stack>
        </Stack>
        <Stack gap={{ xs: 2, sm: 2 }} mt={{ xs: 2, sm: 3 }}>
          <StyledButtonTwo
            width="100%"
            onClick={async () => (account ? buyHandler() : await open())}
          >
            {account ? "Buy Without Staking" : "Connect wallet"}
          </StyledButtonTwo>
          {account && (
            <StyledButtonTwo width="100%" onClick={buyHandlerTwo}>
              Buy & Stake
            </StyledButtonTwo>
          )}
          <StyledButtonTwo
            width="100%"
            startIcon={
              <Box
                component={"img"}
                alt={""}
                src={bitCoinIcon}
                width={"25px"}
              />
            }
          >
            Buy on BSC
          </StyledButtonTwo>
        </Stack>
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "Poppins",
            fontWeight: "500",
            mt: 2,
            color: "#ffffff",
          }}
        >
          Refer a friend and get 5% reward
        </Typography>
        <Box
          sx={{
            background: "#E5E5E5",
            border: "1px solid #000000",
            borderRadius: "20px",
            mb: 2,
            py: 1,
            px: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="subtitle1"
            textAlign="center"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily: "Poppins",
              fontWeight: "500",
            }}
          >
            {account
              ? window?.location?.origin + "/?ref=" + account
              : "Connect wallet and get referral link"}
          </Typography>
          {account ? (
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(
                  window?.location?.origin + "/?ref=" + account
                );
                setAlertState({
                  open: true,
                  message: "Success! Referral link copied",
                  severity: "success",
                });
              }}
            >
              <ContentCopyIcon sx={{ color: "#000000" }} />
            </IconButton>
          ) : null}
        </Box>
        {account && (
          <Stack mt={{ xs: 1.5, sm: 1.5 }}>
            <Typography
              onClick={() => setClaimDilouge(true)}
              variant="subtitle2"
              sx={{
                color: "#FFFFFF",
                fontSize: { xs: "13px", sm: "14px" },
                fontFamily: "Poppins",
                fontWeight: "400",
                textAlign: "center",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Already Purchased? Click here to stake
            </Typography>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}

export default PresaleBox;
