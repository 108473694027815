import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import {
  print,
  print2,
  simg1,
  simg5,
  tokenomicslgmain,
  tokenomicsmobmain,
} from "../components/SmallComponents/Images";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  preSaleAddress,
  tokenAddress,
} from "../ConnectivityAssets/environment";
import { StyledButtonTwo } from "../components/SmallComponents/AppComponents";
import { ToastNotify } from "../components/SmallComponents/AppComponents";

const Tokinomics = () => {
  let tabletScreen = useMediaQuery("(max-width:1050px)");
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  let tokinomicsData = [
    {
      title: "50% PRESALE",
      desc: "Half of total token supply will be set aside for the $TOTE presale. The intention is for all who buy presale to participate in tootie",
    },
    {
      title: "10% DEX",
      desc: "10% of the overall supply is reserved for pairing with a liquidity pool on a decentralized exchange.",
    },
    {
      title: "30% STAKING & BATTLE REWARDS",
      desc: "We have set aside a large percentage of the supply to support that ecosystem and help maintain balance.",
    },
    {
      title: "10% COMMUNITY REWARDS",
      desc: "$T is all about participation and community engagement through the love of epic battles. So we have set aside a good amount of tokens for community rewards.",
    },
  ];
  return (
    <Box mt={20} pb={20} sx={{ position: "relative" }} id={"tokenomics"}>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      {[1, 2, 3].map((_, index) => (
        <Box
          key={index + Math.random() + 30}
          sx={{
            height: "50px",
            width: "50px",
            position: "absolute",
            display: { xs: "none", md: tabletScreen ? "none" : "block" },
            left: {
              xs: "5%",
              md: index === 0 ? "12%" : index === 1 ? "22%" : "72%",
            },
            top: {
              xs: "0%",
              md: index === 0 ? "-5%" : index === 1 ? "-12%" : "-15%",
            },
            zIndex: "1000",
          }}
        >
          <Box
            component="img"
            src={print}
            sx={{ height: "100%", width: "100%" }}
          />
        </Box>
      ))}
      <Box
        sx={{
          height: { xs: "20px", md: "40px" },
          width: { xs: "20px", md: "40px" },
          position: "absolute",
          right: {
            xs: "93%",

            md: tabletScreen ? "10%" : "8%",
          },
          top: {
            xs: "40%",
            md: "-6%",
          },
          zIndex: "1000",
        }}
      >
        <Box
          component="img"
          src={print2}
          sx={{ height: "100%", width: "100%" }}
        />
      </Box>
      <Box
        sx={{
          height: "40px",
          width: "40px",
          position: "absolute",
          display: { xs: "none", md: "block" },
          right: {
            xs: "5%",
            md: tabletScreen ? "90%" : "60%",
          },
          top: {
            xs: "-10%",
            md: tabletScreen ? "40%" : "35%",
          },
          zIndex: tabletScreen ? "0" : "1000",
        }}
      >
        <Box
          component="img"
          src={print2}
          sx={{
            height: "100%",
            width: "100%",
            zIndex: "0",
            position: "relative",
          }}
        />
      </Box>
      <Container maxWidth="xl">
        <Grid container justifyContent={"center"}>
          <Grid
            item
            xs={12}
            md={tabletScreen ? 8 : 6}
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                md: tabletScreen ? "center" : "start",
              },
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                height: {
                  xs: "300px",
                  sm: "430px",
                  md: tabletScreen ? "100%" : "500px",
                  zIndex: "1",
                  position: "relative",
                },
                width: { xs: "80%", md: "90%" },
              }}
            >
              <Box
                component="img"
                src={tabletScreen ? tokenomicsmobmain : tokenomicslgmain}
                alt="tokenomics"
                sx={{ height: "100%", width: "100%" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={tabletScreen ? 8 : 6}>
            <Box>
              {tokinomicsData.map(({ title, desc }, index) => (
                <Box
                  ml={{
                    xs: 0,
                    sm: 0,
                    md: tabletScreen
                      ? 0
                      : index === 0
                      ? -16
                      : index === 1 || index === 2
                      ? -7
                      : -20,
                  }}
                  mt={{
                    xs: 5,
                    md: tabletScreen
                      ? 5
                      : index === 0
                      ? -3
                      : index === 1 || index === 3
                      ? 8
                      : 10,
                  }}
                  key={index}
                  sx={{
                    pr: { xs: 0, md: tabletScreen ? 0 : 10 },
                    textAlign: {
                      xs: "center",
                      md: tabletScreen ? "center" : "left",
                    },
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Londrina Solid",
                      fontSize: { xs: "27px", md: "45px" },
                      fontWeight: 900,
                      lineHeight: { xs: "33px", md: "53px" },
                      letterSpacing: "0em",
                      color: "#826121",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Londrina Solid",
                      fontSize: { xs: "15px", md: "20px" },
                      fontWeight: 400,
                      lineHeight: { xs: "16px", md: "25px" },
                      letterSpacing: "0em",
                      color: "#000000",
                    }}
                  >
                    {desc}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          height: "450px",
          width: "450px",
          position: "absolute",
          right: "0%",
          top: "0%",
          zIndex: "20000",
        }}
      >
        <Box
          component="img"
          src={simg5}
          alt="mini-bg"
          sx={{ height: "100%", width: "100%" }}
        />
      </Box>
      <Box
        sx={{
          height: "350px",
          width: "350px",
          position: "absolute",
          left: "0%",
          top: "60%",
          zIndex: "20000",
        }}
      >
        <Box
          component="img"
          src={simg1}
          alt="mini-bg"
          sx={{ height: "100%", width: "100%" }}
        />
      </Box>
      <Box
        sx={{
          height: { xs: "30px", md: "40px" },
          width: { xs: "30px", md: "40px" },
          position: "absolute",
          right: {
            xs: "25%",
            md: "80%",
          },
          bottom: {
            xs: "5%",
            md: "4%",
          },
          zIndex: "1000",
        }}
      >
        <Box
          component="img"
          src={tabletScreen ? print : print2}
          sx={{ height: "100%", width: "100%" }}
        />
      </Box>
      <Box
        sx={{
          height: { xs: "30px", md: "40px" },
          width: { xs: "30px", md: "40px" },
          position: "absolute",

          right: {
            xs: "14%",
            md: "20%",
          },
          bottom: {
            xs: "50%",
            md: "5%",
          },
          zIndex: "1000",
        }}
      >
        <Box
          component="img"
          src={tabletScreen ? print : print2}
          sx={{ height: "100%", width: "100%" }}
        />
      </Box>
      <Box
        sx={{
          height: "40px",
          width: "40px",
          position: "absolute",
          display: { xs: "none", md: "block" },
          right: {
            xs: "5%",
            md: "10%",
          },
          bottom: {
            xs: "0%",
            md: "40%",
          },
          zIndex: "1000",
        }}
      >
        <Box
          component="img"
          src={print2}
          sx={{ height: "100%", width: "100%" }}
        />
      </Box>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent={"center"} mt={6}>
          {[
            {
              title: "SYMBOL",
              value: "TOTE",
            },
            {
              title: "TOTAL SUPPLY",
              value: "1,000,000,000",
            },
            {
              title: "NETWORK",
              value: "BEP-20",
            },
          ].map(({ title, value }, index) => (
            <Grid item xs={12} sm={6} md={4} key={title}>
              <Box
                sx={{
                  border: "2px solid #997a39",
                  borderRadius: "7px",
                  py: 4,
                }}
              >
                <Typography
                  mt={1}
                  sx={{
                    fontFamily: "Orbitron",
                    fontSize: "26px",
                    fontWeight: 600,
                    lineHeight: "38px",
                    letterSpacing: "0em",
                    textAlign: "center",
                    color: "#826121",
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "15px",
                    letterSpacing: "0em",
                    color: "#000000",

                    textAlign: "center",
                  }}
                >
                  {value}
                </Typography>
              </Box>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Box
              sx={{
                // background: "#000000",
                border: "2px solid #997a39",

                borderRadius: "7px",
                // mt: 6,
                py: 2,
                px: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle1"
                textAlign="center"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontFamily: "Londrina Solid",
                  fontSize: { xs: "20px", md: "30px" },
                }}
              >
                {tokenAddress}
              </Typography>

              <StyledButtonTwo
                width="160px"
                endIcon={<ContentCopyIcon />}
                onClick={() => {
                  navigator.clipboard.writeText(tokenAddress);
                  setAlertState({
                    open: true,
                    message: "Success! Address Copied",
                    severity: "success",
                  });
                }}
              >
                Copy
              </StyledButtonTwo>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Tokinomics;
