import { Box, DialogContent, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { StyledButton, StyledButtonTwo, ToastNotify } from "./AppComponents";
import { StyledModal, Transition } from "../../NetworkSwitch";
import { AppContext } from "../../utils";
import {
  preSaleReadFunction,
  preSaleWriteFunction,
  tokenReadFunction,
  tokenWriteFunction,
} from "../../ConnectivityAssets/hooks";
import { formatUnits, parseUnits } from "viem";
import Loading from "./loading";
import { preSaleAddress } from "../../ConnectivityAssets/environment";

const FlexBox = ({ value1, value2 }) => {
  return (
    <Stack
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      mt={1}
    >
      <Typography
        variant="body1"
        sx={{
          fontFamily: "Poppins",
          fontWeight: { xs: "400", sm: "500" },
          fontSize: { xs: "10px", sm: "14px" },
          lineHeight: "36px",
          textTransform: "uppercase",
          color: "#ffffff",
        }}
      >
        {value1}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontFamily: "Poppins",
          fontWeight: { xs: "400", sm: "500" },
          fontSize: { xs: "10px", sm: "14px" },
          lineHeight: "36px",
          textTransform: "uppercase",
          color: "#ffffff",
        }}
      >
        {value2}
      </Typography>
    </Stack>
  );
};

function ClaimDilouge({ open, setOpen }) {
  const { account } = useContext(AppContext);
  const [userTokenBalance, setUserTokenBalance] = useState(0);
  const [claimData, setClaimData] = useState({
    totalPurchased: 0,
    totalStaked: 0,
    calimed: 0,
    claimable: 0,
  });
  const [loading, setLoading] = useState(false);

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };

  useEffect(() => {
    if (account) {
      (async () => {
        try {
          let tokenBlanceContract = await tokenReadFunction("balanceOf", [
            account,
          ]);
          tokenBlanceContract = +formatUnits(
            tokenBlanceContract?.toString(),
            18
          );
          let userDepositsContract = await preSaleReadFunction("userDeposits", [
            account,
          ]);
          userDepositsContract = parseFloat(
            formatUnits(userDepositsContract?.toString(), 18)
          )?.toFixed(2);
          const userStakedContract = await preSaleReadFunction("userStakes", [
            account,
          ]);
          setUserTokenBalance(tokenBlanceContract);
          setClaimData({
            totalPurchased: userDepositsContract,
            totalStaked: parseFloat(
              formatUnits(userStakedContract[0]?.toString(), 18)
            )?.toFixed(2),
            calimed: parseFloat(
              formatUnits(userStakedContract[1]?.toString(), 18)
            )?.toFixed(2),
            claimable: parseFloat(
              formatUnits(userDepositsContract[2]?.toString(), 18)
            )?.toFixed(2),
          });
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [account]);
  const handleClose = () => {
    setOpen(false);
  };

  const claimHandler = async () => {
    if (!account) {
      return showAlert("Error! Please connect your wallet.");
    }
    try {
      setLoading(true);
      await preSaleWriteFunction("claimAndStake");
      setLoading(false);
      handleClose();
      showAlert("Claimed successfully!", "success");
    } catch (e) {
      setLoading(false);
      showAlert(e?.shortMessage);
    }
  };

  // const claimHandlerTwo = async () => {
  //   if (!account) {
  //     return showAlert("Error! Please connect your wallet.");
  //   }
  //   try {
  //     setLoading(true);
  //     await preSaleWriteFunction("claim");
  //     setLoading(false);
  //     handleClose();
  //     showAlert("Claimed successfully!", "success");
  //   } catch (e) {
  //     setLoading(false);
  //     showAlert(e?.shortMessage);
  //   }
  // };

  const stakeHandler = async () => {
    if (!account) {
      return showAlert("Error! Please connect your wallet.");
    }
    if (+userTokenBalance <= 0) {
      return showAlert("You don't have enough token to stake.");
    }
    try {
      setLoading(true);
      const tokenDecimals = await tokenReadFunction("decimals");
      const checkAllowance = await tokenReadFunction("allowance", [
        account,
        preSaleAddress,
      ]);
      const checkTotalAllowance = +formatUnits(
        checkAllowance.toString(),
        +tokenDecimals?.toString()
      );
      if (+checkTotalAllowance < +userTokenBalance) {
        await tokenWriteFunction("approve", [
          preSaleAddress,
          "999999999999999999999999999999",
        ]);
      }
      await preSaleWriteFunction("stake", [
        parseUnits(userTokenBalance.toString(), +tokenDecimals?.toString()),
      ]);
      setUserTokenBalance(0);
      setLoading(false);
      handleClose();
      showAlert("Staked successfully!", "success");
    } catch (e) {
      setLoading(false);
      showAlert(e?.shortMessage);
    }
  };
  return (
    <div className="modal__main__container">
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <StyledModal
        open={open}
        keepMounted
        TransitionComponent={Transition}
        onClose={handleClose}
        // eslint-disable-next-line jsx-a11y/aria-props
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="dialoge__content__section">
          <Box
            borderRadius="100px"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography
              variant="h3"
              sx={{
                color: "#ffffff",
                fontSize: { xs: "20px", sm: "26px" },
                fontFamily: "Wagoon Bold",
                lineHeight: "30px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              CLAIM
            </Typography>
            <Box width="100%" mt={1}>
              <FlexBox
                value1={`Your Purchased`}
                value2={claimData?.totalPurchased}
              />
              <FlexBox value1={`Your Staked`} value2={claimData?.totalStaked} />
              <FlexBox value1={`CLAIMED`} value2={claimData?.calimed} />
              <FlexBox value1={`CLAIMABLE`} value2={claimData?.claimable} />
            </Box>
          </Box>
          <Stack
            sx={{
              alignItems: "center",
              mt: 2,
            }}
          >
            <StyledButtonTwo width="200px" onClick={stakeHandler}>
              Stake
            </StyledButtonTwo>
          </Stack>{" "}
          <Stack mt={{ xs: 1.5, sm: 1.5 }}>
            <Typography
              onClick={claimHandler}
              variant="subtitle2"
              sx={{
                color: "#FFFFFF",
                fontSize: { xs: "13px", sm: "14px" },
                fontFamily: "Poppins",
                fontWeight: "400",
                textAlign: "center",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Claim your reward and stake? click here
            </Typography>
          </Stack>
        </DialogContent>
      </StyledModal>
    </div>
  );
}

export default ClaimDilouge;
