import React, { useContext } from "react";
import {
  Container,
  Hidden,
  useMediaQuery,
  SwipeableDrawer,
  Button,
  List,
  ListItemText,
  Box,
  Paper,
  ListItemButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import { headerBg, logo } from "./SmallComponents/Images";
import { StyledButton, StyledText } from "./SmallComponents/AppComponents";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { AppContext } from "../utils";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    alignItems: "center",
  },
  paper: {
    background: "#826121 !important",
  },
  hover: {
    "&:hover": {
      color: "#826121",
    },
  },
});

const navArray = [
  {
    text: "utility",
    link: "/#utility",
  },
  {
    text: "how it work ",
    link: "/#how-it-work",
  },
  {
    text: "tokenomics",
    link: "/#tokenomics",
  },
  {
    text: "Roadmap",
    link: "/#roadmap",
  },
  {
    text: "Team",
    link: "/#team",
  },
  {
    text: "Stake",
    link: "#",
  },
];

export default function Header() {
  const matchesTwo = useMediaQuery("(max-width:1050px)");
  const { account } = useContext(AppContext);
  const { open } = useWeb3Modal();
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const matches1 = useMediaQuery("(max-width:1279px)");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box display="flex" justifyContent="center" mt={2}>
        <Link
          to={"/"}
          style={{ textDecoration: "none" }}
          onClick={toggleDrawer(anchor, false)}
        >
          <Box component={"img"} width="110px" src={logo} alt="" />
        </Link>
      </Box>
      <List>
        {navArray?.map(({ text, link }, i) => (
          <ListItemButton key={text}>
            {+i === 5 ? (
              <Link
                to="/stake"
                style={{
                  textDecoration: "none",
                  textTransform: "capitalize",
                  textAlign: "center",
                  width: "100%",
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "#ffffff",
                  fontFamily: "Wagoon Bold",
                  fontSize: "20px",
                }}
              >
                Stake
              </Link>
            ) : (
              <HashLink
                smooth
                to={link}
                style={{ textDecoration: "none", margin: "auto" }}
              >
                <ListItemText
                  sx={{
                    textTransform: "capitalize",
                    textAlign: "center",
                    textDecoration: "none",
                    cursor: "pointer",

                    color: "#ffffff",
                    ".MuiTypography-root": {
                      fontFamily: "Wagoon Bold",
                      fontSize: "20px",
                    },
                  }}
                  primary={text}
                />
              </HashLink>
            )}
          </ListItemButton>
        ))}
      </List>
      <Box mt={1} display="flex" justifyContent="center">
        {/* <HashLink
          smooth
          to={"/#how-to-buy"}
          style={{ textDecoration: "none", margin: "auto" }}
        > */}
        <StyledButton onClick={async () => await open()} width="180px">
          {account ? account.slice(0, 8) + "..." : "Connect wallet"}
        </StyledButton>
        {/* </HashLink> */}
      </Box>
    </div>
  );
  return (
    <>
      <Box
        sx={{
          background: `url(${headerBg}) no-repeat center`,
          backgroundSize: "100% 100%",
        }}
        width="100%"
        py={2}
      >
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Box
                component={"img"}
                width={{ xs: "70px", md: matchesTwo ? "60px" : "80px" }}
                src={logo}
                alt=""
              />
            </Link>
            <Box
              display="flex"
              justifyContent={matches1 ? "end" : "space-between"}
              alignItems="center"
            >
              {!matchesTwo ? (
                <>
                  {navArray?.map(({ text, link }, i) => (
                    <>
                      {+i === 5 ? (
                        <Link to="/stake" style={{ textDecoration: "none" }}>
                          <StyledText key={text} mr={6}>
                            Stake
                          </StyledText>
                        </Link>
                      ) : (
                        <HashLink
                          key={text}
                          smooth
                          to={link}
                          style={{ textDecoration: "none" }}
                        >
                          <StyledText key={text} mr={6}>
                            {text}
                          </StyledText>
                        </HashLink>
                      )}
                    </>
                  ))}
                  {/* <HashLink
                  smooth
                  to={"/#how-to-buy"}
                  style={{ textDecoration: "none" }}
                > */}
                  <StyledButton
                    onClick={async () => await open()}
                    width="160px"
                  >
                    {account ? account.slice(0, 8) + "..." : "Connect wallet"}
                  </StyledButton>
                  {/* </HashLink> */}
                </>
              ) : (
                <>
                  {["left"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Button
                        onClick={toggleDrawer(anchor, true)}
                        style={{ zIndex: 1 }}
                      >
                        <MenuIcon
                          style={{
                            fontSize: "38px",
                            cursor: "pointer",
                            color: "#ffffff",
                          }}
                        />
                      </Button>
                      <Paper>
                        <SwipeableDrawer
                          classes={{ paper: classes.paper }}
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                          onOpen={toggleDrawer(anchor, true)}
                        >
                          {list(anchor)}
                        </SwipeableDrawer>
                      </Paper>
                    </React.Fragment>
                  ))}
                </>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
