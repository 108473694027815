import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { StyledText } from "../components/SmallComponents/AppComponents";
import {
  apyBg,
  heroSectionBg,
  heroStakedInfoBg,
  mobileHeroSectionBg,
  presaleSectionBg,
  puppiesHeroSection,
} from "../components/SmallComponents/Images";
import Header from "../components/Header";
import PresaleBox from "../components/PresaleBox";
// import { preSaleReadFunction } from "../ConnectivityAssets/hooks";

const headingStakedInfoStyle = {
  color: "#FFFFFF",
  fontSize: { xs: "36px", sm: "48px" },
  fontFamily: "Londrina Solid",
  fontWeight: "900",
  textAlign: "center",
};
const paraStakedInfoStyle = {
  color: "#FFFFFF",
  fontSize: { xs: "18px", sm: "20px" },
  fontFamily: "Londrina Solid",
  fontWeight: "400",
  textAlign: "center",
};

function HeroSection({
  totalStakedTokens,
  allStakers,
  totalPaidReward,
  aprPercentage,
  stakedPercentage,
}) {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  const matchesTwo = useMediaQuery("(max-width:1050px)");

  return (
    <>
      <Box
        sx={{
          background: `linear-gradient(0deg, #00000063 0%, #00000063 100%),
           url(${
             mobileMatches ? mobileHeroSectionBg : heroSectionBg
           }) no-repeat center`,

          backgroundSize: "100% 100%",
          // height: "1000px",
          pb: { xs: 50, sm: 70, md: matchesTwo ? 80 : 110 },
        }}
      >
        <Header />
        <Container>
          <Typography
            variant="h1"
            sx={{
              color: "#ffffff",
              fontSize: { xs: "26px", sm: "36px" },
              fontFamily: "Wagoon Bold",
              fontWeight: "400",
              textAlign: "center",
              mt: 6,
            }}
          >
            BUY, STAKE & GET REWARDS
          </Typography>
          <Typography
            variant="h1"
            sx={{
              color: "#ffffff",
              fontSize: { xs: "13px", sm: "14px" },
              fontFamily: "Montserrat",
              lineHeight: "16px",
              fontWeight: "700",
              textAlign: "center",
              mt: 3,
              textTransform: "capitalize",
              mx: { xs: 0, md: matchesTwo ? 10 : 0 },
            }}
          >
            Tootie is an innovative new gaming platform designed for gaming{" "}
            {!matchesTwo && <br />}
            enthusiasts. From active betting to passive staking, there are
            rewards for {!matchesTwo && <br />} all users.
          </Typography>
        </Container>
      </Box>
      <Box
        sx={{
          background: `url(${presaleSectionBg}) no-repeat center`,
          backgroundSize: "100% 100%",
        }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            justifyContent={"center"}
            sx={{
              position: "relative",
              top: { xs: "-110px", sm: "-40px", md: "-300px" },
            }}
          >
            <Grid
              item
              xs={12}
              sm={10}
              md={matchesTwo ? 6 : 5}
              xl={4.5}
              lg={4.5}
            >
              <Box>
                <PresaleBox />
              </Box>
            </Grid>
          </Grid>
          <Box
            mt={{ xs: -8, sm: -3, md: -30 }}
            sx={{
              zIndex: "0",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                component={"img"}
                alt=""
                src={puppiesHeroSection}
                width={{ xs: "100%", sm: "100%", md: "80%" }}
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          background: `url(${heroStakedInfoBg}) no-repeat center`,
          backgroundSize: { xs: "100% 130%", md: "100% 100%" },
          mt: { xs: -0, md: -2 },
          height: { xs: "100%", md: "180px" },
          zIndex: "1",
          position: "relative",
          py: { xs: 5, sm: 3, md: 0 },
        }}
      >
        <Container maxWidth="xl">
          <Grid container justifyContent={"center"} alignItems="center">
            <Grid item xs={12} sm={4} md={2.4} py={4}>
              <Box>
                <Typography variant="body1" sx={paraStakedInfoStyle}>
                  Total $TOTE Staked
                </Typography>
                <Typography variant="h3" sx={headingStakedInfoStyle}>
                  {totalStakedTokens}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={2.4} py={4}>
              <Box>
                <Typography variant="body1" sx={paraStakedInfoStyle}>
                  % of $TOTE Staked
                </Typography>
                <Typography variant="h3" sx={headingStakedInfoStyle}>
                  {stakedPercentage}%
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={2.4}
              py={4}
              sx={{
                background: `url(${apyBg}) no-repeat center`,
                backgroundSize: "100% 100%",
                height: { xs: "100%", md: "200px" },
                mt: { xs: 0, md: -0.5 },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant="body1" sx={paraStakedInfoStyle}>
                  APY
                </Typography>
                <Typography variant="h3" sx={headingStakedInfoStyle}>
                  {aprPercentage}%
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={2.4} py={4}>
              <Box>
                <Typography variant="body1" sx={paraStakedInfoStyle}>
                  Total Rewards Paid ($T)
                </Typography>
                <Typography variant="h3" sx={headingStakedInfoStyle}>
                  {totalPaidReward}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={2.4} py={4}>
              <Box>
                <Typography variant="body1" sx={paraStakedInfoStyle}>
                  Total Stakers
                </Typography>
                <Typography variant="h3" sx={headingStakedInfoStyle}>
                  {allStakers}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default HeroSection;
