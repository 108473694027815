import { Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { StyledButton } from "../../components/SmallComponents/AppComponents";

function UnstakedBox() {
  const mobileMatches = useMediaQuery("(max-width:400px)");
  return (
    <Stack
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
        alignItems: "center",
        py: 3,
        gap: 3,
        height: "100%",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          color: "#75430D",
          fontSize: { xs: "26px", sm: "36px" },
          lineHeight: "46px",
          fontFamily: "Wagoon Bold",
          textAlign: { xs: "center", sm: "left" },
          fontWeight: "400",
        }}
      >
        UNSTAKE{" "}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "#75430D",
          fontSize: { xs: "14px", sm: "16px" },
          fontFamily: "Montserrat",
          textAlign: "center",
          fontWeight: "400",
          mx: 3,
        }}
      >
        Unstaked your tokens and redeem your APY rewards from staking.
      </Typography>
      <Stack gap={3} my={2} width="80%">
        {[
          {
            title: "Unlock time",
            value: "after token launch",
          },
        ].map(({ title, value }) => (
          <Stack
            key={title}
            alignItems={"center"}
            sx={{
              backgroundColor: "#f1efef",
              borderRadius: "8px",
              py: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "#000000",
                fontSize: { xs: "14px", sm: "16px" },
                fontFamily: "Montserrat",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              {title} :{" "}
              <span
                style={{
                  color: "#75430D",
                  fontWeight: "700",
                }}
              >
                {value}
              </span>
            </Typography>
          </Stack>
        ))}
        <Stack
          flexDirection={mobileMatches ? "column" : "row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={3}
        >
          {/* <StyledButton btnStatus={true} width="180px">
            Learn More
          </StyledButton> */}
          <StyledButton width="180px">Unstake now</StyledButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default UnstakedBox;
