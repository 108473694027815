import { Box, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import {
  StyledButton,
  StyledInput,
  ToastNotify,
} from "../../components/SmallComponents/AppComponents";
import { AppContext } from "../../utils";
import {
  preSaleWriteFunction,
  tokenReadFunction,
  tokenWriteFunction,
} from "../../ConnectivityAssets/hooks";
import { preSaleAddress } from "../../ConnectivityAssets/environment";
import { formatUnits, parseUnits } from "viem";
import Loading from "../../components/SmallComponents/loading";

function StakeBox({ currentStaked, claimedReward }) {
  const { account } = useContext(AppContext);
  const [stakeAmount, setStakeAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };

  const handleInputChange = (event, status) => {
    const input = event.target.value;
    const newValue = input?.replace(/[^0-9.]/g, "");

    setStakeAmount(newValue);
  };

  const stakeHandler = async () => {
    if (!account) {
      return showAlert("Error! Please connect your wallet.");
    }
    if (!stakeAmount || +stakeAmount <= 0) {
      return showAlert("Please enter amount");
    }
    try {
      setLoading(true);
      const tokenDecimals = await tokenReadFunction("decimals");
      const checkAllowance = await tokenReadFunction("allowance", [
        account,
        preSaleAddress,
      ]);

      const checkTotalAllowance = +formatUnits(
        checkAllowance.toString(),
        +tokenDecimals?.toString()
      );
      if (+checkTotalAllowance < +stakeAmount) {
        await tokenWriteFunction("approve", [
          preSaleAddress,
          "999999999999999999999999999999",
        ]);
      }
      await preSaleWriteFunction("stake", [
        parseUnits(stakeAmount.toString(), +tokenDecimals?.toString()),
      ]);
      setStakeAmount("");
      setLoading(false);
      showAlert("Staked successfully!", "success");
    } catch (e) {
      setLoading(false);
      showAlert(e?.shortMessage);
    }
  };
  return (
    <Stack
      height="100%"
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
        alignItems: "center",
        py: 3,
        gap: 3,
      }}
    >
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Typography
        variant="h1"
        sx={{
          color: "#75430D",
          fontSize: { xs: "26px", sm: "36px" },
          lineHeight: "46px",
          fontFamily: "Wagoon Bold",
          textAlign: { xs: "center", sm: "left" },
          fontWeight: "400",
        }}
      >
        STAKE{" "}
      </Typography>
      <Box
        sx={{
          width: "80%",
        }}
      >
        <StyledInput
          type="text"
          placeholder="Enter Amount"
          value={stakeAmount}
          onChange={handleInputChange}
        />
      </Box>
      <Box>
        <StyledButton width="180px" onClick={stakeHandler}>
          Stake
        </StyledButton>
      </Box>
      {/* <Typography
        variant="body1"
        sx={{
          color: "#75430D",
          fontSize: { xs: "14px", sm: "16px" },
          fontFamily: "Montserrat",
          textAlign: "center",
          textDecoration: "underline",
          fontWeight: "400",
          mx: 3,
        }}
      >
        Claim any previously purchased tokens here.
      </Typography> */}
      <Stack gap={3} my={1} width="80%">
        {[
          {
            title: "Current staked",
            value: parseFloat(currentStaked).toFixed(0),
          },
          {
            title: "Claimed rewards",
            value: parseFloat(claimedReward).toFixed(0),
          },
        ].map(({ title, value }) => (
          <Stack
            key={title}
            alignItems={"center"}
            sx={{
              backgroundColor: "#f1efef",
              borderRadius: "8px",
              py: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "#000000",
                fontSize: { xs: "14px", sm: "16px" },
                fontFamily: "Montserrat",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              {title} :{" "}
              <span
                style={{
                  color: "#75430D",
                  fontWeight: "700",
                }}
              >
                {value}
              </span>
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default StakeBox;
