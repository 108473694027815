import React, { useState, useEffect } from "react";
import moment from "moment";
import { Box, useMediaQuery } from "@mui/material";

export default function TimerCountDown({ time }) {
  const matches = useMediaQuery("(max-width:650px)");

  const [countTime, setCountDateTime] = useState({
    time_days: 0,
    time_Hours: 0,
    time_Minusts: 0,
    time_seconds: 0,
  });
  const startTime = async () => {
    let until = moment.unix(time).format("x");
    let interval = setInterval(() => {
      let now = moment().format("x");
      const distance = +until - +now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minuts = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setCountDateTime({
          ...countTime,
          time_days: days,
          time_Hours: hours,
          time_Minusts: minuts,
          time_seconds: seconds,
        });
      }
    }, 1000);
  };
  useEffect(() => {
    startTime();
  }, [time]);
  return (
    <>
      <Box
        ml={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={{ xs: 3, sm: 4 }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            borderRadius="12px"
            color="#ffffff"
            fontSize={{ xs: "14px", sm: "22px" }}
            fontWeight="700"
            textAlign="center"
            lineHeight={{ xs: "25px", sm: "30px" }}
            fontFamily="Poppins"
          >
            {countTime.time_days > 9 ? "" : 0}
            {countTime.time_days}
            <br />
            <span
              style={{
                fontSize: matches ? "12px" : "15px",
                fontWeight: "400",
                marginTop: "-20px",
              }}
            >
              Day
            </span>
          </Box>
        </Box>
        {/* <Box mx={2} mb={5} color="#000000" fontWeight="700" fontSize="30px">
          :
        </Box> */}

        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            borderRadius="12px"
            color="#ffffff"
            fontSize={{ xs: "14px", sm: "22px" }}
            fontWeight="700"
            textAlign="center"
            lineHeight={{ xs: "25px", sm: "30px" }}
            fontFamily="Poppins"
          >
            {countTime.time_Hours > 9 ? "" : 0}
            {countTime.time_Hours}
            <br />
            <span
              style={{
                fontSize: matches ? "12px" : "15px",
                fontWeight: "400",
                marginTop: "-20px",
              }}
            >
              {matches ? "Hrs" : "Hours"}
            </span>
          </Box>
        </Box>
        {/* <Box mx={2} mb={5} color="#000000" fontWeight="700" fontSize="30px">
          :
        </Box> */}
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            borderRadius="12px"
            color="#ffffff"
            fontSize={{ xs: "14px", sm: "22px" }}
            fontWeight="700"
            textAlign="center"
            lineHeight={{ xs: "25px", sm: "30px" }}
            fontFamily="Poppins"
          >
            {countTime.time_Minusts > 9 ? "" : 0}
            {countTime.time_Minusts}
            <br />
            <span
              style={{
                fontSize: matches ? "12px" : "15px",
                fontWeight: "400",
                marginTop: "-20px",
              }}
            >
              {matches ? "Mnts" : "Minutes"}
            </span>
          </Box>
        </Box>
        {/* <Box mx={2} mb={5} color="#000000" fontWeight="700" fontSize="30px">
          :
        </Box> */}
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            borderRadius="12px"
            color="#ffffff"
            fontSize={{ xs: "14px", sm: "22px" }}
            fontWeight="700"
            textAlign="center"
            lineHeight={{ xs: "25px", sm: "30px" }}
            fontFamily="Poppins"
          >
            {countTime.time_seconds > 9 ? "" : 0}
            {countTime.time_seconds}
            <br />
            <span
              style={{
                fontSize: matches ? "12px" : "15px",
                fontWeight: "400",
              }}
            >
              {matches ? "Scnds" : "Seconds"}
            </span>
          </Box>
        </Box>
      </Box>
    </>
  );
}
