import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";

export const StyledTableCell = ({ children, position }) => {
  return (
    <TableCell
      align={position ? position : "center"}
      style={{
        borderColor: "#8e6b24",
      }}
    >
      {children}
    </TableCell>
  );
};

function StakingTable({ data }) {
  return (
    <Box
      sx={{
        py: 2,
        mt: { xs: 0, sm: 2 },
        overflow: "scroll",
        height: "350px",
        "&::-webkit-scrollbar": {
          width: 1,
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "none",
        },
        "&::-webkit-scrollbar:horizontal": {
          height: "0px !important",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#3d3e40",
          borderRadius: 2,
        },
      }}
    >
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          borderRadius: "0px",
          background: "none",
          "&::-webkit-scrollbar": {
            width: 1,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "none",
          },
          "&::-webkit-scrollbar:horizontal": {
            height: "0px !important",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#F6F6F6",
            borderRadius: 2,
          },
        }}
      >
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              {[
                "AMOUNT",
                "REWARD CLAIMED",
                "PENDING REWARD",
                "LAST CLAIM TIME",
              ].map((text) => (
                <TableCell
                  key={text}
                  style={{
                    borderColor: "#8e6b24",
                  }}
                  align="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "16px", sm: "18px" }}
                    fontWeight="400"
                    fontFamily={"wagoon Bold"}
                    sx={{
                      color: "#8e6b24",
                    }}
                  >
                    {text}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <StyledTableCell>
                <Typography
                  variant="subtitle2"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  sx={{
                    fontFamily: "Montserrat",
                    color: "#000000",
                  }}
                >
                  {data?.amount}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="subtitle2"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  sx={{
                    fontFamily: "Montserrat",
                    color: "#000000",
                  }}
                >
                  {data?.rewardClaimed}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="subtitle2"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  sx={{
                    fontFamily: "Montserrat",
                    color: "#000000",
                  }}
                >
                  {data?.pendingReward}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="subtitle2"
                  fontSize="16px"
                  sx={{
                    fontFamily: "Montserrat",
                    color: "#000000",
                  }}
                >
                  {data?.lastClaimTime > 0
                    ? moment.unix(data?.lastClaimTime).format("DD/MM/YYYY")
                    : 0}
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default StakingTable;
