import {
  Box,
  Container,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { footerBg } from "./SmallComponents/Images";
import { StyledTitle } from "./SmallComponents/AppComponents";
import { FaTelegramPlane } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { FaTiktok } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";

const socialArray = [
  {
    icon: <FaTelegramPlane style={{ fontSize: "28px", color: "#ffffff" }} />,
    link: "https://t.me/tootiememe",
  },
  {
    icon: <RiTwitterXFill style={{ fontSize: "28px", color: "#ffffff" }} />,
    link: "https://x.com/TootieCoin?t=od827oF9j_-HESBdmOtoag&s=09",
  },
  {
    icon: <FaDiscord style={{ fontSize: "28px", color: "#ffffff" }} />,
    link: "#",
  },
];

function Footer() {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  return (
    <>
      <Box
        sx={{
          background: `url(${footerBg}) no-repeat center`,
          backgroundSize: "100% 100%",
          backgroundColor: "#8e6b24",
          py: 4,
        }}
      >
        <Container maxWidth={"xl"}>
          <StyledTitle textAlign="center">JOIN THE COMMUNITY</StyledTitle>
          <Typography
            varaint="body1"
            sx={{
              textAlign: "center",
              color: "#ffffff",
              fontFamily: "Londrina Solid",
              fontSize: { xs: "18px", sm: "20px" },
              lineHeight: "26px",
              fontWeight: "400",
              mt: 2,
            }}
          >
            Join us in the arena and stay connected with exclusive content and
            {!mobileMatches && <br />}
            updates in our communities!
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              my: 3,
            }}
          >
            {socialArray.map(({ icon, link }, index) =>
              index === 2 ? (
                <Tooltip title="Comming Soon">
                  <IconButton
                    // href={link}
                    // target="_blank"
                    key={index * Math.random() + 1}
                    sx={{
                      backgroundColor: "#490000",
                      "&:hover": {
                        backgroundColor: "#490000",
                      },
                    }}
                  >
                    {icon}
                  </IconButton>
                </Tooltip>
              ) : (
                <IconButton
                  href={link}
                  target="_blank"
                  key={index * Math.random() + 1}
                  sx={{
                    backgroundColor: "#490000",
                    "&:hover": {
                      backgroundColor: "#490000",
                    },
                  }}
                >
                  {icon}
                </IconButton>
              )
            )}
          </Box>
        </Container>
      </Box>
      <Box my={3}>
        <Container maxWidth="xl">
          <Stack
            flexDirection={{ xs: "column", md: "row" }}
            sx={{
              position: "relative",
              gap: { xs: 1, md: 0 },
            }}
          >
            <Box
              position={{ xs: "relative", md: "absolute" }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", md: "start" },
                gap: 2,
              }}
            >
              {[
                {
                  text: "Privacy",
                  link: "#",
                },
                {
                  text: "Cookies",
                  link: "#",
                },
                {
                  text: "Whitepaper",
                  link: "/whitepaper.pdf",
                },
              ].map(({ text, link }) =>
                text === "Whitepaper" ? (
                  <a
                    href={link}
                    target="_blank"
                    key={text}
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <Typography
                      varaint="body1"
                      sx={{
                        textAlign: "center",
                        color: "#826121",
                        fontFamily: "Londrina Solid",
                        fontSize: { xs: "18px", sm: "20px" },
                        lineHeight: "26px",
                        fontWeight: "400",
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {text}
                    </Typography>
                  </a>
                ) : (
                  <Typography
                    key={text}
                    varaint="body1"
                    sx={{
                      textAlign: "center",
                      color: "#826121",
                      fontFamily: "Londrina Solid",
                      fontSize: { xs: "18px", sm: "20px" },
                      lineHeight: "26px",
                      fontWeight: "400",
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {text}
                  </Typography>
                )
              )}
            </Box>
            <Box width={"100%"}>
              <Typography
                varaint="body1"
                sx={{
                  textAlign: "center",
                  color: "#826121",
                  fontFamily: "Londrina Solid",
                  fontSize: { xs: "18px", sm: "20px" },
                  lineHeight: "26px",
                  fontWeight: "400",
                }}
              >
                ® 2024 Tootie Rights Reservedcontact@Tootie
              </Typography>
            </Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
}

export default Footer;
