import { useContext, useState, useEffect } from "react";
import {
  HeroSection,
  PreSaleSection,
  Roadmap,
  OnTheAction,
  Vector,
  OurTeam,
  Tokinomics,
  Staking,
} from "./pages";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useNetwork } from "wagmi";
import { AppContext } from "./utils";
import NetworkSwitch from "./NetworkSwitch";
import {
  preSaleReadFunction,
  tokenReadFunction,
} from "./ConnectivityAssets/hooks";
import { formatUnits } from "viem";

function App() {
  const [callFunction, setCallFunction] = useState(true);
  const [totalStakedTokens, settotalStakedTokens] = useState(0);
  const [allStakers, setallStakers] = useState(0);
  const [totalPaidReward, settotalPaidReward] = useState(0);
  const [aprPercentage, setaprPercentage] = useState(0);
  const [stakedPercentage, setstakedPercentage] = useState(0);
  const { account } = useContext(AppContext);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chain } = useNetwork();

  useEffect(() => {
    if (account && chain && chain?.id !== 56) {
      setOpenNetworkSwitch(true);
    }
  }, [chain, account]);
  const initVoidSigner = async () => {
    try {
      const staked = await preSaleReadFunction("totalStakedAmount");
      const stakers = await preSaleReadFunction("totalStakers");
      const paidReward = await preSaleReadFunction("totalRewardPaid");
      const apyPercentage = await preSaleReadFunction("apr");
      const tokenSupply = await tokenReadFunction("totalSupply");
      let calculations = +formatUnits(tokenSupply?.toString(), 18) / 2;
      let totalStaked = 5169500 + +formatUnits(staked?.toString(), 18);
      calculations = (totalStaked / +calculations) * 100;
      settotalStakedTokens(totalStaked);
      setallStakers(+stakers?.toString() + 74);
      settotalPaidReward(+formatUnits(paidReward?.toString(), 18));
      setaprPercentage(+formatUnits(apyPercentage?.toString(), 3));

      setstakedPercentage(parseFloat(calculations)?.toFixed(2));
      setCallFunction(false);
    } catch (error) {
      setCallFunction(false);
      console.log(error, "ERROR VoidSigner Data");
    }
  };
  useEffect(() => {
    initVoidSigner();
  }, [callFunction]);
  return (
    <>
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeroSection
                totalStakedTokens={totalStakedTokens}
                allStakers={allStakers}
                totalPaidReward={totalPaidReward}
                aprPercentage={aprPercentage}
                stakedPercentage={stakedPercentage}
              />
              <PreSaleSection />
              <OnTheAction />
              <Vector />
              <Tokinomics />
              <Roadmap />
              <OurTeam />
              <Footer />
            </>
          }
        />
        <Route
          path="/stake"
          element={
            <>
              <Staking />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
