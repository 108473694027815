import {
  Alert,
  Box,
  Snackbar,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import { learnMoreBtnbg, walletBtnBg } from "./Images";

export function ToastNotify({ alertState, setAlertState }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={alertState.open}
      autoHideDuration={10000}
      key={"top center"}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );
}

export function StyledButton({ children, btnStatus, ...props }) {
  return (
    <>
      <Button
        {...props}
        disableRipple={true}
        sx={{
          color: "#ffffff",
          backgroundImage: `url(${btnStatus ? learnMoreBtnbg : walletBtnBg})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "transparent",
          justifyContent: "center",
          alignItems: "center",
          textTransform: "capitalize",
          fontFamily: "Poppins",
          fontWeight: "700",
          boxShadow:
            "0px 4px 17px 0px #0000004D,5px -4px 4px 0px #00000040 inset,-4px 4px 4px 0px #FFFFFF40 inset",
          fontSize: "16px",
          width: props?.width,
          height: props?.height ? props?.height : "60px",
          "&.Mui-disabled": {
            color: "#ffffff",
          },
          "&:hover": {
            color: props?.btnStatus ? "#1C1C1C" : "#ffffff",
          },
        }}
      >
        {children}
      </Button>
    </>
  );
}

export function StyledText({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#ffffff",
          fontSize: "24px",
          fontFamily: "Wagoon Bold",
          fontWeight: "400",
          mr: props.mr,
          cursor: "pointer",
          "&:hover": {
            color: "#1C1C1C",
          },
        }}
      >
        {children}
      </Box>
    </>
  );
}
export function StyledTitle({ children, ...props }) {
  return (
    <>
      <Typography
        variant="h1"
        {...props}
        sx={{
          color: props?.color ? props?.color : "#ffffff",
          fontSize: { xs: "28px", sm: "46px" },
          lineHeight: { xs: "50px", sm: "65px" },
          fontFamily: "Londrina Solid",
          fontWeight: "900",
          mr: props.mr,
        }}
      >
        {children}
      </Typography>
    </>
  );
}

export const StyledInput = ({ ...props }) => {
  return (
    <TextField
      {...props}
      sx={{
        background: "#E5E5E5",
        borderRadius: "20px",
        border: "0.56px solid #000000",
        boxShadow:
          "0px 5.913842678070068px 14.78460693359375px 0px #00000026,0px 1.4046640396118164px 1.75583016872406px 0px #FFFFFF inset",
        width: "100%",
        "& .MuiOutlinedInput-root": {
          borderRadius: "12px",
          "& fieldset": {
            border: "none",
          },
          "&:hover fieldset": {
            border: "none",
          },
          "&.Mui-focused fieldset": {
            border: "none",
          },
        },

        input: {
          "&::placeholder": {
            color: "#000000 !important",
            opacity: 1,
          },
          padding: { xs: "16px 16px", sm: "12px 18px" },
          color: "#000000",
          fontSize: "16px",
          fontWeight: "400",
          fontFamily: "Poppins",
        },
      }}
    />
  );
};

export function StyledButtonTwo({ children, btnStatus, ...props }) {
  return (
    <>
      <Button
        {...props}
        disableRipple={true}
        sx={{
          color: "#151515",
          backgroundColor: "#E5E5E5",
          justifyContent: "center",
          alignItems: "center",
          textTransform: "capitalize",
          fontFamily: "Poppins",
          fontWeight: "600",
          border: "0.56px solid #000000",
          boxShadow:
            "0px 5.913842678070068px 14.78460693359375px 0px #00000026,0px 1.4046640396118164px 1.75583016872406px 0px #FFFFFF inset",
          borderRadius: "20px",
          fontSize: "18px",
          width: props?.width,
          height: props?.height ? props?.height : "50px",
          "&.Mui-disabled": {
            color: "#ffffff",
          },
          "&:hover": {
            backgroundColor: "#E5E5E5",
          },
        }}
      >
        {children}
      </Button>
    </>
  );
}

export function StyledTab({ children, ...props }) {
  return (
    <>
      <Tab
        {...props}
        sx={{
          color: "#000000",
          // borderRadius: "30px",
          ml: props?.marginLeft,
          fontSize: { xs: "13px", sm: "16px" },
          fontFamily: "Wagoon Bold",
          fontWeight: "400",
          width: props.width,
          textTransform: "none",
          borderBottom: "2px solid transparent",

          "&.Mui-selected": {
            background: "transparent",
            borderBottom: "2px solid #75430D",
            // borderRadius: "30px",
            color: "#75430D",
            filter: "none",
          },
        }}
        label={children}
      />
    </>
  );
}
