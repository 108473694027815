import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { StyledButton } from "../components/SmallComponents/AppComponents";
import {
  bone1,
  bone2,
  simg3,
  vectorBg,
  vectormainimg,
} from "../components/SmallComponents/Images";

const OnTheAction = () => {

  return (
    <Box mt={10} sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          height: { xs: "60px", md: "100px" },
          width: { xs: "60px", md: "100px" },
          top: { xs: "60%", md: "20%" },
        }}
      >
        <Box
          component="img"
          src={bone1}
          sx={{ height: "100%", width: "100%" }}
        />
      </Box>

      <Container maxWidth="xl">
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Londrina Solid",
              fontSize: { xs: "30px", md: "50px" },
              fontWeight: 900,
              lineHeight: { xs: "54px", md: "84px" },
              letterSpacing: "0em",
              color: "#B6882C",
              textAlign: "center",
            }}
          >
            GET IN ON THE ACTION
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Londrina Solid",
              fontSize: { xs: "18px", md: "25px" },
              fontWeight: 400,
              lineHeight: { xs: "18px", md: "28px" },
              letterSpacing: "0em",
              color: "#000000",
              textAlign: "center",
            }}
          >
            Players buy and stake $T to bet on exciting meme battles, aiming to
            win more <br /> tokens and other prizes!
          </Typography>
          <Box mt={{ xs: 2, sm: 4 }}>
            <StyledButton btnStatus={true} width="180px">
              Learn More
            </StyledButton>
          </Box>
        </Box>
      </Container>

      <Box
        sx={{
          position: "absolute",
          height: "480px",
          width: "480px",
          top: "-90%",
          right: "0%",
        }}
      >
        <Box
          component="img"
          src={simg3}
          sx={{ height: "100%", width: "100%" }}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          height: { xs: "50px", md: "80px" },
          width: { xs: "50px", md: "80px" },
          top: { xs: "0%", md: "20%" },
          right: "0%",
        }}
      >
        <Box
          component="img"
          src={bone2}
          sx={{ height: "100%", width: "100%" }}
        />
      </Box>
    </Box>
  );
};

export default OnTheAction;
