import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import {
  presalemainimg,
  print,
  simg1,
  stakingBg,
  stakingmainimg1,
  stakingmainimg2,
  stakingmainimg3,
  stakingmainimg4,
} from "../components/SmallComponents/Images";
import {
  StyledButton,
  StyledTitle,
} from "../components/SmallComponents/AppComponents";
const PreSaleSection = () => {
  const matchesTwo = useMediaQuery("(max-width:1050px)");
  let stakingData = [
    {
      title: "Buy T$ During presale",
      img: stakingmainimg1,
    },
    {
      title: "Stake Tokens in Tottie Arena",
      img: stakingmainimg2,
    },
    {
      title: "Bet on Battels",
      img: stakingmainimg3,
    },
    {
      title: (
        <span>
          Win Alongside Your
          <br /> Champions
        </span>
      ),
      img: stakingmainimg4,
    },
  ];
  return (
    <Box mt={3} sx={{ position: "relative" }}>
      <Box
        sx={{
          height: "50px",
          width: "50px",
          position: "absolute",
          left: { xs: "5%", md: "10%" },
          top: { xs: "0%", md: "10%" },
          zIndex: "1000",
        }}
      >
        <Box
          component="img"
          src={print}
          sx={{ height: "100%", width: "100%" }}
        />
      </Box>
      <Container maxWidth="xl" sx={{ zIndex: 0 }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            mt={{ xs: 5, sm: 0 }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Londrina Solid",
                  fontSize: { xs: "28px", sm: "40px" },
                  fontWeight: { xs: 500, sm: 900 },
                  lineHeight: "65px",
                  letterSpacing: "0em",
                  color: "#826121",
                }}
              >
                EARN APY DURING PRESALE
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Londrina Solid",
                  fontSize: { xs: "18px", sm: "20px" },
                  fontWeight: 400,
                  lineHeight: "26px",
                  letterSpacing: "0em",
                }}
              >
                Do not wait for battles, begin earning APY on <br /> $T tokens
                today by buying the presale. Click <br /> here to learn more!
              </Typography>
              <Box mt={{ xs: 2, sm: 5 }}>
                <StyledButton btnStatus={true} width="180px">
                  Learn More
                </StyledButton>
              </Box>
              <Box
                sx={{
                  height: "40px",
                  width: "40px",
                  position: "absolute",
                  top: { xs: "10%", md: "25%" },
                  right: { xs: "10%", md: "50%" },
                  zIndex: "20000",
                }}
              >
                <Box
                  component="img"
                  src={print}
                  sx={{ height: "100%", width: "100%" }}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item md={6} xs={12}>
            <Box sx={{ height: { xs: "380px", sm: "580px" } }}>
              <Box
                component="img"
                src={presalemainimg}
                alt="Main PreSale Image"
                sx={{ width: "100%", height: "100%" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          height: "350px",
          width: "350px",
          position: "absolute",
          left: "0%",
          top: "30%",
          zIndex: "20000",
        }}
      >
        <Box
          component="img"
          src={simg1}
          alt="mini-bg"
          sx={{ height: "100%", width: "100%" }}
        />
      </Box>
      <Box
        id="how-it-work"
        sx={{
          background: `url(${stakingBg})`,
          backgroundPosition: "center",
          backgroundSize: { xs: "190% 250%", md: "100% 100%" },
          backgroundRepeat: "no-repeat",
          position: "relative",
          mt: -12,
          zIndex: 20000,
        }}
      >
        <Container maxwidth="xl">
          <Box pt={10} sx={{ textAlign: "center" }}>
            <StyledTitle children="HOW STAKING WORKS" />
            <Typography
              sx={{
                fontFamily: "Londrina Solid",
                fontSize: "22px",
                fontWeight: 400,
                lineHeight: "26px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#FFFFFF",
              }}
            >
              The point of $T isn't to just buy and trade, but to stake your
              token in Tootie and bet on battles for the chance to win big!
              Alongside our regular battles we will also have larger, more hyped
              battles for people to participate in with large prizes for
              winners.
            </Typography>
          </Box>
          <Grid container pt={10} pb={5} spacing={5}>
            {stakingData.map(({ title, img }, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={index + Math.random() + 10}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  sx={{
                    background: `url(${img})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% 100%",
                    backgroundPosition: "center",
                    height: "320px",
                    width: "100%",
                  }}
                >
                  <Typography
                    pt={{ xs: 5, md: matchesTwo ? 4 : 5 }}
                    sx={{
                      fontFamily: "Londrina Solid",
                      fontSize: "24px",
                      fontWeight: 900,
                      lineHeight: "30px",
                      letterSpacing: "0em",
                      color:
                        index === 0 || index === 1 || index === 2
                          ? "#ffffff"
                          : "black",
                      textAlign: "center",
                      px: { xs: 3, md: matchesTwo ? 4 : 3 },
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default PreSaleSection;
