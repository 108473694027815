import { Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import {
  teammainimg,
  teammemberimg,
  ayla,
  dusty,
  khans,
  usman,
  xIcon,
} from "../components/SmallComponents/Images";

let teamData = [
  {
    name: "Ayla Zahra",
    img: ayla,
    desc: "CEO/Founder",
    twitterLink: "https://x.com/0xAyla?t=RYu-muhNvDO3Do2Ba4BhiQ&s=09",
  },
  {
    name: "Dusty",
    img: dusty,
    desc: "Co Founder",
  },
  {
    name: "khans",
    img: khans,
    desc: "Marketing Manager",
  },
  {
    name: "Muhammad Usman",
    img: usman,
    desc: "Blockchain Developer",
  },
];

const OurTeam = () => {
  const lgMatches = useMediaQuery("(max-width:1350px)");
  const mdMatches = useMediaQuery("(max-width:1100px)");
  const mdMatchesTwo = useMediaQuery("(max-width:700px)");

  return (
    <Box id="team" mt={10} pb={5}>
      <Typography
        variant="h5"
        sx={{
          fontFamily: "Londrina Solid",
          fontSize: "22px",
          fontWeight: 400,
          lineHeight: "26px",
          letterSpacing: "0em",
          textAlign: "center",
        }}
      >
        THE Tootie TEAM
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontFamily: "Londrina Solid",
          fontSize: "55px",
          fontWeight: 900,
          lineHeight: "65px",
          letterSpacing: "0em",
          textAlign: "center",
          color: "#826121",
        }}
      >
        MEET OUR TEAM
      </Typography>

      <Container maxWidth="md">
        <Grid container mt={3} spacing={5} justifyContent={"center"}>
          {teamData.map(({ name, img, desc, twitterLink }, index) => (
            <Grid
              item
              xs={12}
              sm={mdMatchesTwo ? 8 : 6}
              md={mdMatches ? 6 : 6}
              mt={5}
              key={index * Math.random() + 4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  background: `url(${teammainimg})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  position: "relative",
                  height: "300px",
                  width: { xs: "100%", md: "80%" },
                }}
              >
                <Box
                  // px={10}
                  pt={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    // ml={{ xs: 3, md: 5 }}

                    sx={{
                      width: "120px",
                      height: "120px",
                    }}
                  >
                    <Box
                      component="img"
                      src={img}
                      sx={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "50%",
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  pt={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <Box>
                    {index === 0 && (
                      <IconButton
                        href={twitterLink}
                        target="_blank"
                        size="small"
                        sx={{ color: "blue" }}
                      >
                        <Box
                          component={"img"}
                          src={xIcon}
                          alt="Linkedin icon"
                          sx={{
                            height: "25px",
                            width: "25px",
                            borderRadius: "50px",
                            bgcolor: "#ffffff",
                            p: 0.5,
                          }}
                        />
                      </IconButton>
                    )}
                  </Box>
                  <Typography
                    mt={1}
                    sx={{
                      fontFamily: "Orbitron",
                      fontSize: "28px",
                      px: 1,
                      fontWeight: 400,
                      lineHeight: "38px",
                      letterSpacing: "0em",
                      color: "#826121",
                    }}
                  >
                    {name}
                  </Typography>
                </Box>
                <Box pb={4}>
                  <Typography
                    pt={2}
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: "20px",
                      fontWeight: 400,
                      lineHeight: "15px",
                      letterSpacing: "0em",
                      color: "#000000",
                      textAlign: "center",
                    }}
                  >
                    {desc}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default OurTeam;
