import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import CircleIcon from "@mui/icons-material/Circle";
import { puppies, roadmapBg } from "../components/SmallComponents/Images";
import { StyledTitle } from "../components/SmallComponents/AppComponents";

const roadmapArray = [
  {
    phaseName: "1",
    title: "PRESALE LAUNCH - March 2024",
    paraOne: "Objective: Kickstart the Tootie project through a token presale",
    textArray: [
      <span>
        {" "}
        <span
          style={{
            color: "#D1A54F",
          }}
        >
          Details:
        </span>{" "}
        50% of the $T tokens will be available for presale, paving the way for
        the platform's launch.
      </span>,
      <span>
        {" "}
        <span
          style={{
            color: "#D1A54F",
          }}
        >
          Outcome:
        </span>{" "}
        A successful presale will provide the essential funds and community
        support required to move forward with the platform's development.
      </span>,
    ],
  },
  {
    phaseName: "2",
    title: "PLATFORM LAUNCH - 2024",
    paraOne: "Objective: Officially launch the Totie platform.",
    textArray: [
      <span>
        {" "}
        <span
          style={{
            color: "#D1A54F",
          }}
        >
          Details:
        </span>{" "}
        Depending on the completed sell-out of the presale, the platform launch
        is planned for first quarter 2024.
      </span>,
      <span>
        {" "}
        <span
          style={{
            color: "#D1A54F",
          }}
        >
          Outcome:
        </span>{" "}
        The launch will mark the commencement of Meme Kombat's operations,
        offering users access to battles, staking, and other features.
      </span>,
    ],
  },
  {
    phaseName: "3",
    title: "SEASON 1 - IMMEDIATE POST-LAUNCH",
    paraOne: "Objective: Inaugurate the first season of Tootie",
    textArray: [
      <span>
        {" "}
        <span
          style={{
            color: "#D1A54F",
          }}
        >
          Details:
        </span>{" "}
        Season 1 will kick off immediately following the platform launch,
        featuring battles, leaderboards, and more.
      </span>,
      <span>
        {" "}
        <span
          style={{
            color: "#D1A54F",
          }}
        >
          Outcome:
        </span>{" "}
        This season will set the stage for continuous gameplay, attracting
        players, and fostering community engagement.
      </span>,
    ],
  },
  {
    phaseName: "4",
    title: "SEASON 2 - 2024",
    paraOne:
      "Objective: Launch the second season of Tootie with enhanced features and experiences.",
    textArray: [
      <span>
        {" "}
        <span
          style={{
            color: "#D1A54F",
          }}
        >
          Details:
        </span>{" "}
        Building on the success of the first season, Season 2 will commence in
        second quarter of 2024, introducing new battle types, rewards, and
        opportunities.
      </span>,
      <span>
        {" "}
        <span
          style={{
            color: "#D1A54F",
          }}
        >
          Outcome:
        </span>{" "}
        Season 2 will further establish Tootie presence in the blockchain gaming
        space, reflecting the project's commitment to innovation and continuous
        improvement.
      </span>,
    ],
  },
  {
    phaseName: "5",
    title: "FUTURE DEVELOPMENT",
    paraOne:
      "Continuous Upgrades: Tootie will continue to evolve, with ongoing updates to the platform, gameplay, tokenomics, and more.",
    textArray: [
      <span>
        {" "}
        <span
          style={{
            color: "#D1A54F",
          }}
        >
          Community Engagement:
        </span>{" "}
        Ongoing interaction with the community will shape future seasons and
        features, aligning with player preferences and industry trends.
      </span>,
      <span>
        {" "}
        <span
          style={{
            color: "#D1A54F",
          }}
        >
          Expansion Plans:
        </span>{" "}
        Looking beyond 2024, the project has plans for further expansion,
        potentially adding new game modes, partnerships, and other exciting
        developments.
      </span>,
    ],
  },
];

const headingStyle = {
  //   mt: 0.5,
  color: "#D1A54F",
  fontSize: { xs: "22px", sm: "26px" },
  fontWeight: "900",
  lineHeight: "26px",
  fontFamily: "Londrina Solid",
};
const paraOneStyle = {
  color: "#ffffff",
  fontSize: { xs: "16px", sm: "18px" },
  fontWeight: "400",
  lineHeight: "24px",
  fontFamily: "Londrina Solid",
};

const paraStyle = {
  lineHeight: "24px",
  color: "#ffffff",
  fontSize: "16px",
  fontWeight: "400",
  fontFamily: "Londrina Solid",
};
export default function Roadmap() {
  const matches = useMediaQuery("(max-width:950px)");
  const matchesTwo = useMediaQuery("(max-width:1200px)");

  return (
    <Box
      py={{ xs: 20, sm: 15 }}
      id="roadmap"
      overflow={"hidden"}
      sx={{
        background: `url(${roadmapBg}) no-repeat center`,
        backgroundSize: "100% 100%",
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component={"img"}
            alt=""
            src={puppies}
            width={{ xs: "50%", sm: "25%", md: "18%", lg: "15%", xl: "14%" }}
          />
        </Box>
        <Typography
          varaint="body1"
          sx={{
            textAlign: "center",
            color: "#ffffff",
            fontFamily: "Londrina Solid",
            fontSize: { xs: "20px", sm: "24px" },
            lineHeight: "26px",
            fontWeight: "400",
            mt: 0.5,
          }}
        >
          WHAT CAN YOU EXPECT
        </Typography>
        <StyledTitle textAlign="center" color="#D1A54F">
          TOOTIE ROADMAP
        </StyledTitle>
        <Typography
          varaint="body1"
          sx={{
            textAlign: "center",
            color: "#ffffff",
            fontFamily: "Londrina Solid",
            fontSize: { xs: "48px", sm: "60px" },
            lineHeight: "80px",
            fontWeight: "900",
            mt: 2,
          }}
        >
          2024
        </Typography>
        <Box mt={{ xs: 8, sm: 8 }} className="scrolling-vertical-timeline">
          <VerticalTimeline
            animate={true}
            layout={"2-columns"}
            lineColor="#ffffff"
          >
            {roadmapArray?.map(({ title, paraOne, textArray }, i) =>
              (i + 1) % 2 === 1 ? (
                <VerticalTimelineElement
                  key={title}
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    backgroundColor: "transparent",
                    color: "#000",
                    border: "none",
                    borderRadius: "20px",
                    padding: "0px",
                    boxShadow: "none",
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    flexDirection: "column",
                  }}
                  contentArrowStyle={{ display: "none" }}
                  iconStyle={{
                    border: "none",
                    marginLeft: matches
                      ? "-14px"
                      : matchesTwo
                      ? "-14px"
                      : "-53px",
                    marginTop: matches ? "15px" : matchesTwo ? "20px" : "0px",
                    outline: "none",
                    boxShadow: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  icon={
                    <CircleIcon
                      style={{ color: "#ffffff", fontSize: "20px" }}
                    />
                  }
                >
                  <Box
                    mt={1}
                    p={3}
                    bgcolor="transparent"
                    borderRadius="15px"
                    boxShadow="3.61562px 9.64167px 20.4885px rgb(0 0 0 / 15%)"
                    width={matches ? "100%" : matchesTwo ? "100%" : "90%"}
                  >
                    <Box mb={2} display="flex" alignItems="center">
                      <Box sx={headingStyle}>{title}</Box>
                    </Box>
                    <Box mb={2} display="flex" alignItems="center">
                      <Box sx={paraOneStyle}>{paraOne}</Box>
                    </Box>
                    {textArray?.map((text) => (
                      <Box key={text} display=" flex" mt={2}>
                        <Box key={text} sx={paraStyle}>
                          {text}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </VerticalTimelineElement>
              ) : (
                <VerticalTimelineElement
                  key={title}
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    backgroundColor: "transparent",
                    color: "#000",
                    border: "none",
                    borderRadius: "20px",
                    padding: "0px",
                    boxShadow: "none",
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    flexDirection: "column",
                  }}
                  contentArrowStyle={{ display: "none" }}
                  iconStyle={{
                    border: "none",
                    marginLeft: matches
                      ? "-14px"
                      : matchesTwo
                      ? "-14px"
                      : "-53px",
                    marginTop: matches ? "15px" : "20px",
                    outline: "none",
                    boxShadow: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  icon={
                    <CircleIcon
                      style={{ color: "#ffffff", fontSize: "20px" }}
                    />
                  }
                >
                  <Box
                    mt={1}
                    p={3}
                    bgcolor="transparent"
                    borderRadius="15px"
                    boxShadow="3.61562px 9.64167px 20.4885px rgb(0 0 0 / 15%)"
                    width={matches ? "100%" : matchesTwo ? "100%" : "90%"}
                  >
                    <Box mb={2} display="flex" alignItems="center">
                      <Box sx={headingStyle}>{title}</Box>
                    </Box>
                    <Box mb={2} display="flex" alignItems="center">
                      <Box sx={paraOneStyle}>{paraOne}</Box>
                    </Box>

                    {textArray?.map((text) => (
                      <Box display=" flex" key={text} mt={2}>
                        <Box sx={paraStyle}>{text}</Box>
                      </Box>
                    ))}
                  </Box>
                </VerticalTimelineElement>
              )
            )}
          </VerticalTimeline>
        </Box>
      </Container>
    </Box>
  );
}
