import { Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useContext, useState } from "react";
import {
  StyledButton,
  ToastNotify,
} from "../../components/SmallComponents/AppComponents";
import { AppContext } from "../../utils";
import { preSaleWriteFunction } from "../../ConnectivityAssets/hooks";
import Loading from "../../components/SmallComponents/loading";

function ClaimBox({ pendingReward }) {
  const { account } = useContext(AppContext);
  const mobileMatches = useMediaQuery("(max-width:400px)");
  const [loading, setLoading] = useState(false);

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };

  const claimHandler = async () => {
    if (!account) {
      return showAlert("Error! Please connect your wallet.");
    }
    try {
      setLoading(true);
      await preSaleWriteFunction("claimAndStake");
      setLoading(false);
      showAlert("Claimed successfully!", "success");
    } catch (e) {
      setLoading(false);
      showAlert(e?.shortMessage);
    }
  };

  const claimHandlerTwo = async () => {
    if (!account) {
      return showAlert("Error! Please connect your wallet.");
    }
    try {
      setLoading(true);
      await preSaleWriteFunction("claim");
      setLoading(false);
      showAlert("Claimed successfully!", "success");
    } catch (e) {
      setLoading(false);
      showAlert(e?.shortMessage);
    }
  };
  return (
    <Stack
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
        alignItems: "center",
        py: 3,
        gap: 3,
        height: "100%",
      }}
    >
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Typography
        variant="h1"
        sx={{
          color: "#75430D",
          fontSize: { xs: "26px", sm: "36px" },
          lineHeight: "46px",
          fontFamily: "Wagoon Bold",
          textAlign: { xs: "center", sm: "left" },
          fontWeight: "400",
        }}
      >
        CLAIM{" "}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "#75430D",
          fontSize: { xs: "14px", sm: "16px" },
          fontFamily: "Montserrat",
          textAlign: "center",
          fontWeight: "400",
          mx: 3,
        }}
      >
        Claim your staking rewards.
      </Typography>
      <Stack gap={3} my={2} width="80%">
        {[
          {
            title: "Unclaimed rewards",
            value: parseFloat(pendingReward).toFixed(0),
          },
        ].map(({ title, value }) => (
          <Stack
            key={title}
            alignItems={"center"}
            sx={{
              backgroundColor: "#f1efef",
              borderRadius: "8px",
              py: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "#000000",
                fontSize: { xs: "14px", sm: "16px" },
                fontFamily: "Montserrat",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              {title} :{" "}
              <span
                style={{
                  color: "#75430D",
                  fontWeight: "700",
                }}
              >
                {value}
              </span>
            </Typography>
          </Stack>
        ))}
        <Stack
          flexDirection={mobileMatches ? "column" : "row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={3}
        >
          {/* <StyledButton btnStatus={true} width="180px">
            Learn More
          </StyledButton> */}
          <StyledButton width="180px" onClick={claimHandler}>
            Claim & Stake
          </StyledButton>
          <StyledButton width="180px" onClick={claimHandlerTwo}>
            Claim now
          </StyledButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ClaimBox;
