import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import {
  simg4,
  vectorBg,
  vectormainimg,
} from "../components/SmallComponents/Images";
import {
  StyledButton,
  StyledTitle,
} from "../components/SmallComponents/AppComponents";

const Vector = () => {
  const matchesTwo = useMediaQuery("(max-width:1050px)");

  return (
    <Box
      mt={-5}
      pb={5}
      pt={{ xs: 0, md: matchesTwo ? 10 : 0 }}
      mb={5}
      sx={{
        background: `url(${vectorBg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        backgroundSize: {
          xs: "180% 100%",
          md: matchesTwo ? "100% 100%" : "100% 100%",
        },
        position: "relative",
      }}
    >
      <Box sx={{ height: "350px", width: "350px", position: "absolute" }}>
        <Box
          component="img"
          src={simg4}
          sx={{ height: "100%", width: "100%" }}
        />
      </Box>
      <Container maxWidth="xl">
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            mt={{ xs: 5, md: 0 }}
            sx={{
              display: "flex",
              alignItems: "end",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                height: "80%",
                width: "60%",
              }}
            >
              <Box
                component="img"
                src={vectormainimg}
                sx={{ height: "100%", width: "100%" }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            mb={4}
            mt={{ xs: 5, md: 0 }}
            sx={{
              display: "flex",
              alignItems: "end",
              justifyContent: "center",
            }}
          >
            <Box pt={{ xs: 1, sm: 0 }}>
              <StyledTitle children="CHOOSE YOUR VICTOR" />
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Londrina Solid",
                  fontSize: "22px",
                  fontWeight: 400,
                  lineHeight: "26px",
                  letterSpacing: "0em",
                  color: "#ffffff",
                }}
              >
                In Season 1 we have 11 Tooties all fighting to be <br /> #1.
                Will you find a favorite? Or play the odds?
              </Typography>
              <Box mt={{ xs: 2, sm: 3 }}>
                <StyledButton btnStatus={true} width="180px">
                  Learn More
                </StyledButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Vector;
